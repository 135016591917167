<script>
import Swal from 'sweetalert2';
import Moment from 'moment';
import VCards from '../components/Cards.vue';
import VSteps from '../components/Steps.vue';
import VExpensesTable from '../components/ExpensesTable.vue';

export default {
  components: {
    VExpensesTable,
    VCards,
    VSteps
  },
  data() {
    return {
      idContaCartaoDespesas: null,
      showError: false,
      date: 7,
      startDate: 0,
      endDate: 0
    }
  },
  methods: {
    showAlert() {
      Swal.fire({
        title: 'OnlyPay Informa',
        html: '<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">Estamos com instabilidade em nosso sistema.<br>Nosso time está trabalhando para resolução o mais breve possível. <br>Pedimos desculpas pelo transtorno. <br><br>Pedimos que direcione sua solicitações e dúvidas para faleconosco@onlypay.com.br.<p>',
        type: 'info',
        confirmButtonText: 'Ok'
      })
    },
    checkDate(value) {
      if (value) {
        this.startDate = 0;
        this.endDate = 0;
      }
    },
    nextStep(card) {
      const endDate = Moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
      const startDate = Moment(new Date(), 'YYYY-MM-DD').subtract(this.date, 'days').format('YYYY-MM-DD');
      this.idContaCartaoDespesas = card.idConta.toString()
      this.$router.push({
        name: 'expenses-details',
        query: {
          c: card.idConta,
          start: this.startDate !== 0 ? this.startDate : startDate,
          end: this.endDate !== 0 ? this.endDate : endDate
        }
      });
      return true
    }
  }
};
</script>

<template>
  <div class="expenses">
    <v-steps :current="idContaCartaoDespesas ? 2 : 1" :length="2" class="" />
    <div v-if="!idContaCartaoDespesas">
      <p><strong>Selecione o período e o cartão.</strong></p>
      <br>
      <div class="row px-2 mb-4">
        <label class="container col-3">7 Dias
          <input type="radio" value="7" v-model="date" @click="checkDate('7')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="container col-3">30 Dias
          <input type="radio" value="30" v-model="date" @click="checkDate('30')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="container col-3">60 Dias
          <input type="radio" value="60" v-model="date" @click="checkDate('60')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="container col-3">90 Dias
          <input type="radio" value="90" v-model="date" @click="checkDate('90')">
          <span class="checkmark ml-2"></span>
        </label>
        <label class="d-none d-sm-flex flex-column col-6"> Data início:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="startDate"
            required />
        </label>
        <label class="d-none d-sm-flex flex-column col-6"> Data fim:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="endDate"
            required />
        </label>
          <!-- Display in mobile -->
        <label class="d-flex d-sm-none flex-column col-6"> Data início:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="startDate"
            required />
        </label>
        <label class="d-flex d-sm-none flex-column col-6"> Data fim:
          <input class="px-2" style="border: 1px solid gray; border-radius: 4px;" type="date" v-model="endDate"
            required />
        </label>
        <!-- Display inmobile -->
      </div>

      <!-- label="Data de Pagamento" class="d-flex flex-column w-100 m-0" -->

      <v-cards v-on:click="nextStep" :showActions="false" :showBalance="true" :showCanceledCard="true"/>
    </div>
    <v-expenses-table v-else/>
  </div>
</template>

<style lang="postcss" scoped>
.expenses{
  @media screen and (max-width: 991px) {
    /* padding-top: 5vh; */
  }
  .container {
    display: inline-block;
    width: auto;
    position: relative;
    padding-left: 40px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 5px;
    height: 20px;
    width: 20px;
    background-color: #8bbbd285;
    border-radius: 50%;
  }

  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .container input:checked ~ .checkmark {
    background-color: #7dd080;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 7px;
    left: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }
}
</style>
